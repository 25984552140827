<template>
  <span class="task-id">
    <template v-if="task.identifier === ''">
	    #{{ task.index }}
    </template>
    <template v-else>
	    {{ task.identifier }}
    </template>
  </span>
</template>

<script setup lang="ts">
import {ref, watch, shallowReactive, onMounted, onBeforeUnmount, computed} from 'vue'
import TaskModel from '@/models/task'
import type {ITask} from '@/modelTypes/ITask'

const {
	theTask,
} = defineProps<{
	theTask: ITask,
}>()

const task = ref<ITask>(new TaskModel())

watch(
	() => theTask,
	newVal => {
		task.value = newVal
	},
)
</script>

<style lang="scss" scoped>
.task-id {
  color: var(--grey-500);
  font-size: 0.8rem;
  margin-bottom: 0.25rem;
  margin-right: 0.25rem;
}
</style>
